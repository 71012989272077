export const topics = [
  {
    label: 'Opleidingen',
    value: 'programs',
  },
  {
    label: 'Studies',
    value: 'studies',
  },
  {
    label: 'Universiteiten',
    value: 'universities',
  },
  {
    label: 'Hogescholen',
    value: 'HBOs',
  },
  {
    label: 'Banen',
    value: 'jobs',
  },
  {
    label: 'Traineeships',
    value: 'traineeships',
  },
  {
    label: 'Stages',
    value: 'internships',
  },
  {
    label: 'Companies',
    value: 'companies',
  },
  {
    label: 'Evenementen',
    value: 'events',
  },
];

export const studyLevel = [
  {
    label: 'HBO Associate degree',
    value: 'HBO Associate degree',
  },
  {
    label: 'HBO Bachelor',
    value: 'HBO Bachelor',
  },
  {
    label: 'HBO Master',
    value: 'HBO Master',
  },
  {
    label: 'WO Bachelor',
    value: 'WO Bachelor',
  },
  {
    label: 'WO Master',
    value: 'WO Master',
  },
];

export const studentSatisfactionRate = [
  {
    label: 'Alles',
    value: 'All',
  },
  {
    label: '4.5 en meer',
    value: '4.5 and Up',
  },
  {
    label: '4.0 en meer',
    value: '4 and Up',
  },
  {
    label: '3.5 en meer',
    value: '3.5 and Up',
  },
  {
    label: '3.0 en meer',
    value: '3 and Up',
  },
];

export const studyField = [
  {
    label: 'Economie en Bedrijf',
    value: 'Economie en Bedrijf',
  },
  {
    label: 'Onderwijs en Opvoeding',
    value: 'Onderwijs en Opvoeding',
  },
  {
    label: 'Art and Culture',
    value: 'Kunst en Cultuur',
  },
  {
    label: 'Health',
    value: 'Gezondheid',
  },
  {
    label: 'Techniek',
    value: 'Techniek',
  },
  {
    label: 'Exact en Informatica',
    value: 'Exact en Informatica',
  },
  {
    label: 'Gedrag en Maatschappij',
    value: 'Gedrag en Maatschappij',
  },
  {
    label: 'Recht en Bestuur',
    value: 'Recht en Bestuur',
  },
  {
    label: 'Taal en Communicatie',
    value: 'Taal en Communicatie',
  },
  {
    label: 'Aarde en Milieu',
    value: 'Aarde en Milieu',
  },
  {
    label: 'Interdisciplinair',
    value: 'Interdisciplinair',
  },
];

export const trainingForm = [
  {
    label: 'Voltijd',
    value: 'Full time',
  },
  {
    label: 'Deeltijd',
    value: 'Part time',
  },
  {
    label: 'Duaal',
    value: 'Dual',
  },
];

export const startingMoment = [
  {
    label: 'Januari',
    value: 'January',
  },
  {
    label: 'Februari',
    value: 'February',
  },
  {
    label: 'Maart',
    value: 'March',
  },
  {
    label: 'April',
    value: 'April',
  },
  {
    label: 'Mei',
    value: 'May',
  },
  {
    label: 'Juni',
    value: 'June',
  },
  {
    label: 'Juli',
    value: 'July',
  },
  {
    label: 'Augustus',
    value: 'August',
  },
  {
    label: 'September',
    value: 'September',
  },
  {
    label: 'Oktober',
    value: 'October',
  },
  {
    label: 'November',
    value: 'November',
  },
  {
    label: 'December',
    value: 'December',
  },
];

export const salaryRate = [
  {
    label: '0 - 1000 EUR',
    value: '0 - 1000 EUR',
  },
  {
    label: '1000 - 2000 EUR',
    value: '1000 - 2000 EUR',
  },
  {
    label: '2000 - 3000 EUR',
    value: '2000 - 3000 EUR',
  },
  {
    label: '3000 + EUR',
    value: '3000 + EUR',
  },
];

export const workExperience = [
  {
    label: '0 tot 1 jaar',
    value: '0 to 1 years',
  },
  {
    label: '1 tot 3 jaar',
    value: '1 to 3 years',
  },
  {
    label: '3 tot 5 jaar',
    value: '3 to 5 years',
  },
  {
    label: '5 tot 10 jaar',
    value: '5 to 10 years',
  },
  {
    label: '10 jaar +',
    value: '10 years +',
  },
];

export const jobFunction = [
  {
    label: 'Accountancy',
    value: 'Accounting',
  },
  {
    label: 'Administratief',
    value: 'Administratief',
  },
  {
    label: 'Advertising',
    value: 'Advertising',
  },
  {
    label: 'Analist',
    value: 'Analyst',
  },
  {
    label: 'Belastingen',
    value: 'Tax',
  },
  {
    label: 'Bijles',
    value: 'Bijles',
  },
  {
    label: 'Business',
    value: 'General Business',
  },
  {
    label: 'Business development',
    value: 'Business development',
  },
  {
    label: 'Callcenter',
    value: 'Callcenter',
  },
  {
    label: 'Chauffeur',
    value: 'Chauffeur',
  },
  {
    label: 'Communicatie',
    value: 'Communication',
  },
  {
    label: 'Consultancy',
    value: 'Consulting',
  },
  {
    label: 'Customer service',
    value: 'Customer Service',
  },
  {
    label: 'Design',
    value: 'Design',
  },
  {
    label: 'Distributie',
    value: 'Destribution',
  },
  {
    label: 'Engineering',
    value: 'Engineering',
  },
  {
    label: 'Evenementen',
    value: 'Events',
  },
  {
    label: 'Financieel',
    value: 'Financieel',
  },
  {
    label: 'Gezondheid',
    value: 'Healthcare',
  },
  {
    label: 'Horeca',
    value: 'Hotel Restaurant Cafe',
  },
  {
    label: 'HR',
    value: 'Human Resources',
  },
  {
    label: 'ICT',
    value: 'ICT',
  },
  {
    label: 'Inkoop',
    value: 'Purchasing',
  },
  {
    label: 'IT',
    value: 'IT',
  },
  {
    label: 'Juridisch',
    value: 'Legal',
  },
  {
    label: 'Logistiek',
    value: 'Logistiek',
  },
  {
    label: 'Bezorger',
    value: 'Bezorger',
  },
  {
    label: 'Management',
    value: 'Management',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
  },
  {
    label: 'Medical',
    value: 'Medical',
  },
  {
    label: 'Onderwijs',
    value: 'Education',
  },
  {
    label: 'Product Management',
    value: 'Product Management',
  },
  {
    label: 'Productie',
    value: 'Production',
  },
  {
    label: 'QA',
    value: 'Quality Assurance',
  },
  {
    label: 'Redactie',
    value: 'Writing and Editing',
  },
  {
    label: 'Research',
    value: 'Research',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Strategie en planning',
    value: 'Strategy & Planning',
  },
  {
    label: 'Supply chain',
    value: 'Supply Chain',
  },
  {
    label: 'Thuiszorg/Zorg',
    value: 'Zorg',
  },
  {
    label: 'Vervoer',
    value: 'Transport',
  },
  {
    label: 'Wetenschap',
    value: 'Science',
  },
];

export const jobFunctionsNew = [
  {
    label: 'Bijles geven',
    value: 'Bijles',
  },
  {
    label: 'Bezorger',
    value: 'Bezorger',
  },
  {
    label: 'Chauffeur',
    value: 'Chauffeur',
  },
  {
    label: 'Callcenter medewerker',
    value: 'Callcenter',
  },
  {
    label: 'Thuiszorg medewerker',
    value: 'Zorg',
  },
  {
    label: 'Schoonmaak medewerker',
    value: 'schoonmaak',
  },
  {
    label: 'Administratief werk',
    value: 'Administratief',
  },
  {
    label: 'Logistiek',
    value: 'Logistiek',
  },
  {
    label: 'ICT',
    value: 'ICT',
  },
  {
    label: 'Financieel',
    value: 'Financieel',
  },
  {
    label: 'Horeca',
    value: 'Hotel Restaurant Cafe',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
];

export const trendingJobFunctionsMap = {
  bijles: { name: 'Bijles', value: 'Bijles geven', href: 'bijles' },
  chauffeur: { name: 'Chauffeur', value: 'Chauffeur', href: 'chauffeur' },
  callcenter: {
    name: 'Callcenter medewerker',
    value: 'Callcenter medewerker',
    href: 'callcenter',
  },
  zorg: {
    name: 'Zorg',
    value: 'Zorg',
    href: 'zorg',
  },
  administratieve: {
    name: 'Administratief',
    value: 'Administratief',
    href: 'administratieve',
  },
  schoonmaak: {
    name: 'Schoonmaak',
    value: 'Schoonmaak',
    href: 'schoonmaak',
  },
  logistiek: { name: 'Logistiek', value: 'Logistics', href: 'logistiek' },
  bezorger: { name: 'Bezorger', value: 'Bezorger', href: 'bezorger' },
  ict: { name: 'ICT', value: 'ICT', href: 'ict' },
  horeca: { name: 'Horeca', value: 'Hotel Restaurant Cafe', href: 'horeca' },
};

export const hours = [
  {
    label: 'Full time',
    value: 'Full time',
  },
  {
    label: 'Part time',
    value: 'Part time',
  },
  {
    label: 'Vrijwilligerswerk',
    value: 'Volunteer',
  },
];
export const employment = [
  {
    label: 'Baan',
    value: 'job',
  },
  {
    label: 'Traineeship',
    value: 'traineeship',
  },
  {
    label: 'Stage',
    value: 'internship',
  },
];

export const education = [
  {
    label: 'Middelbare school',
    value: 'Middelbare school',
  },
  {
    label: 'MBO',
    value: 'MBO',
  },
  {
    label: 'HBO',
    value: 'HBO',
  },
  {
    label: 'Universiteit',
    value: 'Universiteit',
  },
];

export const industry = [
  {
    label: 'Accounting',
    value: 'Accounting',
  },
  {
    label: 'Ruimtevaart',
    value: 'Aerospace & Defence',
  },
  {
    label: 'Landbouw',
    value: 'Agriculture',
  },
  {
    label: 'Architectuur ',
    value: 'Architecture & Planning',
  },
  {
    label: 'Kunst en cultuur',
    value: 'Arts & Culture',
  },
  {
    label: 'Automotive',
    value: 'Automotive',
  },
  {
    label: 'Chemie',
    value: 'Chemical',
  },
  {
    label: 'Bouw',
    value: 'Construction',
  },
  {
    label: 'Design',
    value: 'Design',
  },
  {
    label: 'Onderwijs',
    value: 'Education',
  },
  {
    label: 'Energie',
    value: 'Energy',
  },
  {
    label: 'Engineering',
    value: 'Engineering',
  },
  {
    label: 'Entertainment',
    value: 'Entertainment',
  },
  {
    label: 'Finance ',
    value: 'Finance & Banking',
  },
  {
    label: 'FMCG',
    value: 'FMCG',
  },
  {
    label: 'Food',
    value: 'Food',
  },
  {
    label: 'Overheid',
    value: 'Government',
  },
  {
    label: 'Gezondheid',
    value: 'Healthcare',
  },
  {
    label: 'Hospitality',
    value: 'Hospitality',
  },
  {
    label: 'Verzekeringen',
    value: 'Insurance',
  },
  {
    label: 'Investment Banking',
    value: 'Investment banking',
  },
  {
    label: 'IT',
    value: 'IT',
  },
  {
    label: 'Juridisch',
    value: 'Legal',
  },
  {
    label: 'Toursime',
    value: 'Leisure, Travel & Tourism',
  },
  {
    label: 'Logistiek',
    value: 'Logistics',
  },
  {
    label: 'Managements consulting',
    value: 'Management Consulting',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Maritieme sector',
    value: 'Marine & Offshore',
  },
  {
    label: 'Marketing en communicatie',
    value: 'Marketing & Communication',
  },
  {
    label: 'Media',
    value: 'Media',
  },
  {
    label: 'Mijbouw',
    value: 'Mining',
  },
  {
    label: 'Non-profit',
    value: 'Non-profit & Charity',
  },
  {
    label: 'Farmacie',
    value: 'Pharmaceutical',
  },
  {
    label: 'Uiterlijke verzorging',
    value: 'Beauty',
  },
  {
    label: 'Uitgeverij',
    value: 'Publishing',
  },
  {
    label: 'Vastgoed',
    value: 'Real estate',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Staffing',
    value: 'Staffing',
  },
  {
    label: 'Telecom',
    value: 'Telecom',
  },
  {
    label: 'Tabaksindustrie',
    value: 'Tobacco Industry',
  },
  {
    label: 'Venture Capital en Private equity',
    value: 'Venture capital & Private equity',
  },
  {
    label: 'Zorg',
    value: 'Care',
  },
  {
    label: 'Overig',
    value: 'Other',
  },
];

export const companySize = [
  {
    label: '1 tot 10 medewerkers',
    value: '1 to 10 employees',
  },
  {
    label: '11 tot 50 medewerkers',
    value: '11 to 50 employees',
  },
  {
    label: '51 tot 200 medewerkers',
    value: '51 to 200 employees',
  },
  {
    label: '201 tot 500 medewerkers',
    value: '201 to 500 employees',
  },
  {
    label: '501 tot 1000 medewerkers',
    value: '501 to 1000 employees',
  },
  {
    label: 'Meer dan 1000 medewerkers',
    value: 'More than 1000 employees',
  },
];

export const languages = [
  {
    label: 'Nederlands',
    value: 'Dutch',
  },
  {
    label: 'Engels',
    value: 'English',
  },
  {
    label: 'Duitse',
    value: 'German',
  },
  {
    label: 'Frans',
    value: 'French',
  },
  {
    label: 'Spaans',
    value: 'Spanish',
  },
  {
    label: 'Italiaans',
    value: 'Italian',
  },
];

export const isVerified = [
  {
    label: 'Partner van Studenten.nl',
    value: 'true',
  },
];

export const jobType = [
  {
    label: 'Baan',
    value: 'job',
  },
  {
    label: 'Traineeship',
    value: 'traineeship',
  },
  {
    label: 'Stage',
    value: 'internship',
  },
];

export const activityType = [
  {
    label: 'Datum',
    value: 'Join day',
  },
  {
    label: 'Online',
    value: 'Online',
  },
  {
    label: 'Open dag / avond',
    value: 'Open day/evening',
  },
  {
    label: 'Proef studeren',
    value: 'Trial study',
  },
];

export const feeType = [
  {
    label: 'Gratis',
    value: 'Free of charge',
  },
  {
    label: 'Betaald',
    value: 'Paid',
  },
];

export const areaOfInterest = [
  {
    label: 'Cultuur',
    value: 'Culture',
  },
  {
    label: 'Sport',
    value: 'Sports',
  },
  {
    label: 'Gaming',
    value: 'Gaming',
  },
  {
    label: 'Algemeen',
    value: 'General',
  },
  {
    label: 'Internationaal',
    value: 'International',
  },
  {
    label: 'Carrière, opleiding en werkgelegenheid',
    value: 'Career, training and employment',
  },
  {
    label: 'Studie',
    value: 'Study',
  },
  {
    label: 'Liefdadigheid',
    value: 'Charity',
  },
  {
    label: 'Religie',
    value: 'Religion',
  },
  {
    label: 'LGBT',
    value: 'LGBT',
  },
  {
    label: 'Alternatief',
    value: 'Alternative',
  },
];

export const gender = [
  {
    label: 'Only woman',
    value: 'woman',
  },
  {
    label: 'Only men',
    value: 'men',
  },
  {
    label: 'Mixed',
    value: 'mixed',
  },
];

export const corporal = [
  {
    label: 'Ja',
    value: 'true',
  },
  {
    label: 'Nee',
    value: 'false',
  },
];

export const initiation = [
  {
    label: 'Ja',
    value: 'true',
  },
  {
    label: 'Nee',
    value: 'false',
  },
];

export const housingType = [
  {
    label: 'Kamer',
    value: 'room',
  },
  {
    label: 'Apartment',
    value: 'apartment',
  },
  {
    label: 'Studio',
    value: 'studio',
  },
  {
    label: 'Anti-kraak',
    value: 'anti-kraak',
  },
];

export const priceRange = [
  {
    label: '0 - 99 EUR',
    value: '0 - 99 EUR',
  },
  {
    label: '100 - 199 EUR',
    value: '100 - 199 EUR',
  },
  {
    label: '200 - 299 EUR',
    value: '200 - 299 EUR',
  },
  {
    label: '300 - 499 EUR',
    value: '300 - 499 EUR',
  },
  {
    label: '500+ EUR',
    value: '500+ EUR',
  },
];

export const commercialType = [
  {
    label: 'Particulier',
    value: 'Commercial',
  },
  {
    label: 'Niet particulier',
    value: 'NonCommercial',
  },
];

export const facilities = [
  {
    label: 'Privé badkamer',
    value: 'Private bathroom',
  },
  {
    label: 'Privé toilet',
    value: 'Private toilet',
  },
  {
    label: 'Privé keuken',
    value: 'Private kitchen',
  },
  {
    label: 'Internet',
    value: 'Internet',
  },
  {
    label: 'Airco',
    value: 'Air Conditioning',
  },
  {
    label: 'Wasmachine',
    value: 'Washing machine',
  },
  {
    label: 'Vaatwassen',
    value: 'Dishwasher',
  },
  {
    label: 'Droger',
    value: 'Tumble dryer',
  },
  {
    label: 'Roken toegestaan',
    value: 'smoking',
  },
  {
    label: 'Huisdieren',
    value: 'pets',
  },
];

export const membershipFee = [
  {
    label: 'Geen',
    value: 'free',
  },
  {
    label: 'Wel',
    value: 'paid',
  },
];

export const lifestyleType = [
  {
    label: 'Cafe en restaurants',
    value: 'Cafe and Restaurants',
  },
  {
    label: 'Fitness en sport',
    value: 'Gym and Sports',
  },
  {
    label: 'Bibliotheken en boekhandels',
    value: 'Libraries and Bookshops',
  },
  {
    label: 'Kleding',
    value: 'Clothing and Shoes',
  },
  {
    label: 'Huishoudelijk',
    value: 'Household stuff / In rooms',
  },
  {
    label: 'Gezondheid',
    value: 'Healthcare',
  },
  {
    label: 'Cultuur',
    value: 'Culture',
  },
  {
    label: 'Copyshops',
    value: 'Copyshops',
  },
  {
    label: 'Rijscholen',
    value: 'Driving schools',
  },
  {
    label: 'Bioscopen',
    value: 'Cinema',
  },
  {
    label: 'Gemeente en Politiek',
    value: 'Municipality and Politics',
  },
  {
    label: 'Beauty en Haar',
    value: 'Beauty and Hair',
  },
];

export const healthcareType = [
  {
    value: 'dentist',
    label: 'Dentist',
  },
  {
    value: 'practitioner',
    label: 'Practitioner',
  },
  {
    value: 'std consult',
    label: 'Std consult',
  },
  {
    value: 'traveler vaccination,',
    label: 'Traveler vaccination,',
  },
  {
    value: 'ggd',
    label: 'Ggd',
  },
  {
    value: 'healthinsurance',
    label: 'Healthinsurance',
  },
  {
    value: 'psychology',
    label: 'Psychology',
  },
  {
    value: 'general practitioner',
    label: 'General practitioner',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const LifestyleTypeCity = [
  ...lifestyleType,
  { label: 'Popular employer', value: 'Popular employer' },
];

export const typeOfInstitution = [
  {
    value: 'private',
    label: 'Local',
  },
  {
    value: 'public',
    label: 'Employer',
  },
];

export const popularCities = [
  { label: 'Amsterdam', value: 'Amsterdam' },
  { label: 'Rotterdam', value: 'Rotterdam' },
  { label: 'Utrecht', value: 'Utrecht' },
  { label: 'Groningen', value: 'Groningen' },
  { label: 'Nijmegen', value: 'Nijmegen' },
  { label: 'Eindhoven', value: 'Eindhoven' },
  { label: 'Tilburg', value: 'Tilburg' },
  { label: 'Enschede', value: 'Enschede' },
  { label: 'Leiden', value: 'Leiden' },
  { label: 'Delft', value: 'Delft' },
  { label: 'Maastricht', value: 'Maastricht' },
  { label: 'Den Haag', value: 'Den Haag' },
  { label: 'Wageningen', value: 'Wageningen' },
  { label: 'Leeuwarden', value: 'Leeuwarden' },
  { label: 'Almere', value: 'Almere' },
  { label: 'Den Bosch', value: 'Den Bosch' },
  { label: 'Breda', value: 'Breda' },
  { label: 'Arnhem', value: 'Arnhem' },
  { label: 'Deventer', value: 'Deventer' },
  { label: 'Zwolle', value: 'Zwolle' },
  { label: 'Haarlem', value: 'Haarlem' },
  { label: 'Amersfoort', value: 'Amersfoort' },
  { label: 'Heerlen', value: 'Heerlen' },
  { label: 'Middelburg', value: 'Middelburg' },
  { label: 'Alkmaar', value: 'Alkmaar' },
];
